import {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import { CardActionElementBaseProps, CardRef } from '@type/card';
import { useTalentCardActions } from '@components/templates/employer/cards-lists/hooks/useTalentCardActions';
import {
  Anchor, Button, Modal,
  Dropdown, DropdownButton, DropdownItems, DropdownItem,
} from '@components/common';
import { useTranslation } from 'next-i18next';
import { SendMessageModalContent } from '@components/templates/cv/partials/modals/SendMessageModalContent';
import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid';
import { AddressBookSolidIcon } from '@components/common/Icons';
import { CvDetailHub } from '@components/templates/cv/CvDetailHub';
import { useModalHistory } from '@hooks/history/useModalHistory';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { ProfessionalExperienceForm } from '@components/templates/profile/experience/edit/ProfessionalExperienceForm';
import { useFeedTracking } from '@hooks/tracking/useFeedTracking';
import { useRouter } from 'next/router';
import { dataTestId } from '@services/data-test-id';


// ***************************************************************
export const CardActionsForUser = forwardRef<CardRef, CardActionElementBaseProps>((props, ref) => {
  // destructure props
  const {
    isReviewCard,
    candidateName,
    candidateSlug,
    jobSlug,
    isAuthenticated,
    isCompany,
    locale,
    eventType,
    eventId,
    requestId,
    itemPosition,
    userSlug,
  } = props;

  const { t } = useTranslation('common');
  const { updateModalHistory } = useModalHistory();
  const { trackEvent } = useFeedTracking();
  const router = useRouter();

  const [mountTime, setMountTime] = useState<number>(0);

  useEffect(() => {
    const startTime = Date.now();
    setMountTime(startTime);
  }, []);

  const logEventAndSendTag = useCallback(
    (interactionType: string, tagEvent?: string) => {
      const currentTime = Date.now();
      const durationInSeconds = currentTime - mountTime;

      trackEvent({
        position: itemPosition,
        event_id: eventId,
        request_id: requestId,
        event_type: eventType,
        interaction: interactionType,
        created_at: new Date().toISOString(),
        duration: durationInSeconds,
        context: 'Context',
        userSlug,
      });

      if (tagEvent) {
        sendGoogleTagEvent(tagEvent, 'Custom');
      }
    },
    [trackEvent, itemPosition, eventId, requestId, eventType, mountTime, userSlug],
  );


  const [openExperienceModal, setOpenExperienceModal] = useState<boolean>(false);

  const experience = {
    company: '',
    employerSlug: '',
  };

  const {
    unlockWithMessage,
    unlockByContactData,
    toggleSendMessageModal,
    displaySendMessageModal,
    displayOpenCvDetail,
    toggleDisplayCvDetail,
  } = useTalentCardActions({
    userSlug: candidateSlug ?? '',
    locale: locale ?? 'ro',
    revalidatePage: (() => {}),
    hasInterviewFolder: false,
    interviewFolders: [],
  });

  const onSuccessSubmit = useCallback(() => {
    sendGoogleTagEvent('feed_action_saved_rating', 'Custom');
    setOpenExperienceModal(false);
  }, []);

  const getFooterButtons = () => {
    if (!isAuthenticated) {
      const actionLabel = (() => {
        switch (eventType) {
          case 'company-review':
            return t('feed.action.label.evaluate');
          case 'candidate-hire':
            return t('cv.detail.action.see.cv');
          case 'salary-add':
            return t('cv.detail.action.see.salaries');
          default:
            return t('cv.detail.action.contact.label');
        }
      })();

      return (
        <Anchor
          href="login"
          symfonyRoute
          target="_blank"
          styling="none"
          className="block min-w-full rounded-full bg-secondary/10 px-4.5 py-2.5 text-center text-sm font-medium text-secondary hover:bg-secondary hover:text-surface"
          {...dataTestId('eventsFeedCard.footer.login')}
        >
          {actionLabel}
        </Anchor>
      );
    }


    if (isReviewCard && !isCompany) {
      return (
        <>
          <Modal
            isOpen={openExperienceModal}
            size="lg"
            body={(
              <div className="p-5">

                <div className="flex items-center justify-between border-b pb-4">
                  <h6 className="mb-0 mr-2 text-xl font-semibold">{t('cv.section.label.professional_experience')}</h6>
                  <Button color="light" size="sm" className="-mr-2 ml-3 !rounded-full !p-2" onClick={() => { setOpenExperienceModal(false); }}>
                    <XMarkIcon className="size-5" />
                  </Button>
                </div>
                <ProfessionalExperienceForm withSuggestions experience={experience} locale={locale as Locale} onSuccessSubmit={onSuccessSubmit} />
              </div>
            )}
            onClose={() => { setOpenExperienceModal(false); }}
          />
          <div className="mx-6 space-x-1.5">
            <Button
              size="sm"
              rounding="full"
              styling="none"
              onClick={() => {
                logEventAndSendTag('experience_modal_open');
                setOpenExperienceModal(true);
              }}
              className="block min-w-full rounded-full bg-secondary/10 px-4.5 py-2.5 text-center text-sm font-medium text-secondary hover:bg-secondary hover:text-surface"
              {...dataTestId('eventsFeedCard.footer.experience')}
            >
              {t('feed.action.label.evaluate')}
            </Button>
          </div>
        </>

      );
    }

    if (eventType === 'salary-add' && !isCompany) {
      return (
        <Button
          size="sm"
          rounding="full"
          styling="none"
          onClick={() => {
            logEventAndSendTag('salaries');
            void router.push('/salaries');
          }}
          className="block min-w-full rounded-full bg-secondary/10 px-4.5 py-2.5 text-center text-sm font-medium text-secondary hover:bg-secondary hover:text-surface"
          {...dataTestId('eventsFeedCard.footer.salaryAdd')}
        >
          {t('cv.detail.action.see.salaries')}
        </Button>
      );
    }

    if (eventType === 'candidate-hire' && !isCompany) {
      return (
        <Button
          styling="none"
          onClick={() => {
            updateModalHistory({
              pathname: `/cv/${userSlug}`,
            });
            logEventAndSendTag('cv_detail');
            toggleDisplayCvDetail();
          }}
          className="block min-w-full rounded-full bg-secondary/10 px-4.5 py-2.5 text-center text-sm font-medium text-secondary hover:bg-secondary hover:text-surface"
          {...dataTestId('eventsFeedCard.footer.candidateHire')}
        >
          {t('cv.detail.action.see.cv')}
        </Button>
      );
    }

    return (
      <Dropdown>
        <DropdownButton
          as={Button}
          size="sm"
          rounding="full"
          styling="none"
          className="min-w-full rounded-full bg-secondary/10 px-4.5 py-2.5 text-sm font-medium text-secondary hover:bg-secondary hover:text-surface"
        >
          <>
            {t('cv.detail.action.contact.label')}
          </>
        </DropdownButton>

        {/* Dropdown list */}
        <DropdownItems animation="slideUp" className="bottom-full z-[99] mb-2 flex min-w-full flex-col items-start gap-3 !rounded-md p-4">

          {/* Send message */}
          <DropdownItem
            as={Button}
            styling="text"
            color="ink"
            onClick={() => {
              logEventAndSendTag('unlock_message', 'feed_action_unlock_with_message');
              unlockWithMessage();
            }}
          >
            <ChatBubbleLeftEllipsisIcon className="mr-2 size-4" />
            <span className="flex items-center text-sm">
              {t('cv.detail.action.message.label')}
            </span>
          </DropdownItem>

          {/* View contact data */}
          <DropdownItem
            as={Button}
            styling="text"
            color="ink"
            onClick={() => {
              logEventAndSendTag('unlock_contact', 'feed_action_unlock_with_contact_data');
              unlockByContactData();
            }}
          >
            <div>
              <AddressBookSolidIcon className="mr-2.5 h-4 w-3.5 fill-ink" />
            </div>
            <span className="text-sm">
              {t('cv.detail.action.see_contact.label')}
            </span>
          </DropdownItem>
        </DropdownItems>
      </Dropdown>
    );
  };

  return (
    <div className="mx-6 space-x-1.5" ref={ref}>
      <Modal
        isOpen={displayOpenCvDetail}
        onClose={toggleDisplayCvDetail}
        size="full"
        className="!transform-none !overflow-visible"
        beforeLeave={() => updateModalHistory({
          pathname: '/',
        })}
        body={(
          <>
            <CvDetailHub
              slug={candidateSlug}
              jobSlug={jobSlug}
              isAuthenticated={isAuthenticated}
              closeModal={toggleDisplayCvDetail}
            />
          </>
        )}
      />

      {/* Modal for message send */}
      <Modal
        isOpen={displaySendMessageModal}
        body={(
          <SendMessageModalContent
            locale={locale}
            candidateName={candidateName || ''}
            onSendSuccess={() => toggleSendMessageModal()}
            onClose={() => { toggleSendMessageModal(); }}
            slug={candidateSlug || ''}
          />
                    )}
        onClose={() => { toggleSendMessageModal(); }}
      />

      {getFooterButtons()}

    </div>
  );
});
